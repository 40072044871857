// extracted by mini-css-extract-plugin
export var lbContainerOuter = "S_xw";
export var lbContainerInner = "S_xx";
export var movingForwards = "S_xy";
export var movingForwardsOther = "S_xz";
export var movingBackwards = "S_xB";
export var movingBackwardsOther = "S_xC";
export var lbImage = "S_xD";
export var lbOtherImage = "S_xF";
export var prevButton = "S_xG";
export var nextButton = "S_xH";
export var closing = "S_xJ";
export var appear = "S_xK";