// extracted by mini-css-extract-plugin
export var alignLeft = "s_qv d_fp d_bG d_dv";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignRight = "s_qw d_fr d_bH d_dx";
export var FAQMainHeader = "s_kt d_kt d_w d_by";
export var FAQComponentHeader = "s_kz d_kz d_bx";
export var FAQComponentParagraph = "s_kv d_kv d_cD";
export var FAQComponentsWrapper = "s_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "s_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "s_kB d_kB d_by";
export var accordion__bodyHidden = "s_kD d_kD d_bw d_dl";
export var FAQDivider = "s_kq d_kq";
export var accordionWrapper = "s_qZ d_cg";
export var accordion__item = "s_q0 d_cL";
export var accordion_arrow = "s_kH d_kH";
export var accordion_arrow_open = "s_kK d_kK";
export var accordion_arrow_2 = "s_kM d_kM";
export var accordion_arrow_2_open = "s_kP d_kP";
export var Subtitle2Small = "s_q1 H_q1 H_tt H_tK";
export var Subtitle2Normal = "s_q2 H_q2 H_tt H_tL";
export var Subtitle2Large = "s_q3 H_q3 H_tt H_tM";