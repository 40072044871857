import React from 'react';
import ReactDOM from 'react-dom';

import * as styles from './styles.module.css';

const VideoBox = ({ frame, onCloseClick, variant = 'default' }) => {
  const close =
    variant === 'default' ? (
      <button onClick={onCloseClick} className={styles.boxBtn}>
        <i className={`oi-icon oi-26-close ${styles.boxIcon}`} />
      </button>
    ) : (
      <div className={styles.btnWrapper}>
        <button onClick={onCloseClick} className={styles.closeBtn}>
          <i className={`entypo icon-cancel ${styles.boxIcon}`} />
        </button>
      </div>
    );

  const content = (
    <div className={styles.box}>
      {close}
      {frame}
    </div>
  );

  return ReactDOM.createPortal(content, document.body);
};

export default VideoBox;
