// extracted by mini-css-extract-plugin
export var alignLeft = "p_qv d_fp d_bG d_dv";
export var alignCenter = "p_bP d_fq d_bD d_dw";
export var alignRight = "p_qw d_fr d_bH d_dx";
export var comparisonContainer = "p_qx d_dX";
export var comparisonContainerFull = "p_qy d_dV";
export var comparisonFloatyBox = "p_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "p_jb d_jb d_w d_c5";
export var comparisonRow = "p_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "p_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "p_h3 d_h3 d_w";
export var comparisonBtnWrapper = "p_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "p_jk d_jk d_w";
export var comparisonImageContainer = "p_jg d_jg d_Z d_by d_w";
export var stretch = "p_qz";
export var limit = "p_qB";
export var exceptionWeight = "p_qC H_tW";