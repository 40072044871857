// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "z_sh d_bD";
export var storyRowWrapper = "z_hx d_hx d_bK";
export var storyLeftWrapper = "z_sj d_bz d_bP";
export var storyWrapperFull = "z_sk d_cD";
export var storyWrapperFullLeft = "z_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "z_mv d_hy";
export var storyLeftWrapperCenter = "z_sl d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "z_sm d_hF";
export var storyHeader = "z_sn d_hD d_w d_cs";
export var storyHeaderCenter = "z_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "z_hB d_hB d_by d_dw";
export var storyBtnWrapper = "z_sp d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "z_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "z_sq d_fg d_Z";
export var imageWrapperFull = "z_sr d_w d_H d_bf d_Z";
export var SubtitleSmall = "z_qf H_qf H_tt H_tG";
export var SubtitleNormal = "z_qg H_qg H_tt H_tH";
export var SubtitleLarge = "z_qh H_qh H_tt H_tJ";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";