// extracted by mini-css-extract-plugin
export var alignLeft = "B_qv d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qw d_fr d_bH d_dx";
export var testimonialsContainer = "B_ss d_dW";
export var testimonialsContainerFull = "B_st d_dT";
export var testimonialsMainHeader = "B_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "B_jy d_jy";
export var testimonialsComponentSmall = "B_jx d_jx";
export var testimonialsComponentsWrapper = "B_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "B_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "B_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "B_sv d_d7";
export var colEqualHeight = "B_sw d_bz";
export var testimonialsColumnWrapper = "B_jz d_jz d_b3";
export var testimonialsImageWrapper = "B_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "B_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "B_jr d_jr d_Z d_bz";
export var design2TextWrapper = "B_sx d_bC d_bP d_bJ";
export var design3 = "B_sy d_cD d_H";
export var imageDesign2 = "B_sz";
export var SmallSmall = "B_sB H_sB H_tt H_tR";
export var SmallNormal = "B_sC H_sC H_tt H_tS";
export var SmallLarge = "B_sD H_sD H_tt H_tP";
export var exceptionWeight = "B_qC H_tW";