import React from 'react';

import * as styles from './styles.module.css';

const VerticalDotted = ({ id, compContent, index, align, bg, color, first, last, imgIcon }) => {
  const boxes = [];
  for (let i = 0; i < 4; i += 1) {
    const box = (
      <div
        key={`${id}_Dotted_Box_${index}${i}`}
        className={`${styles[`boxWrapper${i === 0 ? 'First' : ''}`]} ${styles[`align${align}`]}`}
      >
        <div className={styles.box} style={{ backgroundColor: color }} />
      </div>
    );
    boxes.push(box);
  }

  return (
    <div
      className={`col-12 col-md-7 ${styles[`align${align}`]}${
        index === first ? ` ${styles.verticalDottedFirstComponent}` : ''
      } ${styles.wrap}${bg !== undefined && imgIcon !== null ? ` ${styles.topPadding}` : ''}`}
      style={bg !== undefined ? bg : {}}
    >
      <div className={`${styles.compContent} ${index === last ? ` ${styles.last}` : ''}`}>{compContent}</div>
      <div className={index === last ? styles.last : ''} />
      {index !== last && boxes}
    </div>
  );
};

export default VerticalDotted;
