// extracted by mini-css-extract-plugin
export var noMargin = "n_qj d_cc";
export var carouselCol = "n_qk d_cD";
export var carouselWrapper = "n_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "n_k7 d_k7 d_H d_w";
export var carouselContainer = "n_ql d_cD";
export var carouselContainerFull = "n_qm d_dT";
export var carouselImg = "n_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "n_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "n_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "n_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "n_kZ d_kZ";
export var imageAltSmall = "n_qn d_k4";
export var imageAltNormal = "n_qp d_k4";
export var imageAltLarge = "n_qq d_k4";
export var imageTextSmall = "n_qr d_k5";
export var imageTextNormal = "n_qs d_k5";
export var imageTextLarge = "n_qt d_k5";