// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_rH d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_rJ d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_rK d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_rL d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_rM d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_rN d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_rP d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_rQ H_rQ";
export var heroExceptionNormal = "w_rR H_rR";
export var heroExceptionLarge = "w_rS H_rS";
export var Title1Small = "w_rT H_rT H_tt H_tv";
export var Title1Normal = "w_rV H_rV H_tt H_tw";
export var Title1Large = "w_rW H_rW H_tt H_tx";
export var BodySmall = "w_rX H_rX H_tt H_tN";
export var BodyNormal = "w_rY H_rY H_tt H_tP";
export var BodyLarge = "w_rZ H_rZ H_tt H_tQ";