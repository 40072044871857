// extracted by mini-css-extract-plugin
export var alignLeft = "M_qv d_fp d_bG d_dv";
export var alignCenter = "M_bP d_fq d_bD d_dw";
export var alignRight = "M_qw d_fr d_bH d_dx";
export var verticalSolidComponent = "M_vG";
export var compContent = "M_vz d_r";
export var normal = "M_vH d_Z d_cb";
export var reverse = "M_vJ d_Z d_bK";
export var last = "M_vB";
export var empty = "M_vK d_r";
export var empty2 = "M_vL d_bw";
export var borderRight = "M_vM d_b8 d_c1";
export var borderLeft = "M_vN d_b9 d_c1";