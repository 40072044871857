// extracted by mini-css-extract-plugin
export var alignLeft = "L_qv d_fp d_bG d_dv";
export var alignCenter = "L_bP d_fq d_bD d_dw";
export var alignRight = "L_qw d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "L_vw";
export var wrap = "L_vx d_bJ d_cb";
export var topPadding = "L_vy d_cX";
export var compContent = "L_vz d_w";
export var last = "L_vB";
export var box = "L_vC d_cr";
export var boxWrapper = "L_vD d_w d_bz";
export var boxWrapperFirst = "L_vF d_w d_bz";