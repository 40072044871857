// extracted by mini-css-extract-plugin
export var carouselContainer = "R_ql d_w d_H d_bf d_Z";
export var carouselContainerCards = "R_wK R_ql d_w d_H d_bf d_Z";
export var carouselContainerSides = "R_wL d_w d_H d_Z";
export var prevCarouselItem = "R_wM d_w d_H d_0 d_k";
export var prevCarouselItemL = "R_wN R_wM d_w d_H d_0 d_k";
export var moveInFromLeft = "R_wP";
export var prevCarouselItemR = "R_wQ R_wM d_w d_H d_0 d_k";
export var moveInFromRight = "R_wR";
export var selectedCarouselItem = "R_wS d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "R_wT R_wS d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "R_wV R_wS d_w d_H d_Z d_bf";
export var nextCarouselItem = "R_wW d_w d_H d_0 d_k";
export var nextCarouselItemL = "R_wX R_wW d_w d_H d_0 d_k";
export var nextCarouselItemR = "R_wY R_wW d_w d_H d_0 d_k";
export var arrowContainer = "R_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "R_w0 R_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "R_w1 R_w0 R_wZ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "R_w2 R_wZ d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "R_w3";
export var nextArrowContainerHidden = "R_w4 R_w2 R_wZ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "R_kG d_0";
export var prevArrow = "R_w5 R_kG d_0";
export var nextArrow = "R_w6 R_kG d_0";
export var carouselIndicatorContainer = "R_w7 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "R_w8 d_w d_bz d_bF";
export var carouselText = "R_w9 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "R_xb R_w9 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "R_xc d_b7";
export var carouselIndicator = "R_xd R_xc d_b7";
export var carouselIndicatorSelected = "R_xf R_xc d_b7";
export var arrowsContainerTopRight = "R_xg d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "R_xh d_0 d_bl d_bC";
export var arrowsContainerSides = "R_xj d_0 d_bl d_bC";
export var smallArrowsBase = "R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "R_xl R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "R_xm R_xl R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "R_xn R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "R_xp R_xn R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "R_qb";
export var iconSmall = "R_xq";
export var multipleWrapper = "R_xr d_bC d_bF d_bf";
export var multipleImage = "R_xs d_bC";
export var sidesPrevContainer = "R_xt R_xl R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "R_xv R_xl R_xk d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";