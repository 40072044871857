// extracted by mini-css-extract-plugin
export var customText = "r_qS d_dv d_cs d_cg";
export var videoIframeStyle = "r_pT d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_qT d_cs d_cg d_Z";
export var customRow = "r_qc d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_pY d_H";
export var masonryImageWrapper = "r_p4";
export var title = "r_qV";
export var Title3Small = "r_qW H_qW H_tt H_tC";
export var Title3Normal = "r_qX H_qX H_tt H_tD";
export var Title3Large = "r_qY H_qY H_tt H_tF";